function rejectTransform(error) {
    try {
        let response = error;
        let error_values = findErrorResponse(response);
        if (error_values) {
            return {error: error_values}
        }
        return Promise.reject(error)
    } catch (e) {
        return Promise.reject(e)
    }
}

function findErrorResponse(response) {
    let error_entry = Array.isArray(response) ?
        response.filter(item => item.hasOwnProperty('type') && ((item.type === 'api_error') ||
            (item.type === "mysql")))
        : response.hasOwnProperty('error_api') && response.error_api.hasOwnProperty('api_error') ?
            response.error_api.api_error :
            response.hasOwnProperty('error_api') && response.error_api.hasOwnProperty('response_error') ?
                response.error_api.response_error
                :  response.hasOwnProperty('error_api') && response.error_api.hasOwnProperty('mysql')
                ? response.error_api.mysql
                :null
    ;
    let errors = '';

    if (Array.isArray(error_entry) && !!error_entry.length) {
        error_entry.forEach(e => {

            if (typeof e.error === 'string') {
                errors += `${e.error},`;
            } else {
                for (const key in e.error) {
                    if (Object.prototype.hasOwnProperty.call(e.error, key)) {
                        errors += `${e.error[key]},`;
                    }
                }
            }
        });
    }

    return errors;
}

function resolveTransform(response) {
    let data = response.data;
    let error = findErrorResponse(data);
    return !error ? data : {error: error}
}



export {findErrorResponse, rejectTransform, resolveTransform}